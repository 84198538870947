.news--feed {
  @include ul-reset;
  display: flex;
  flex-wrap: wrap;

  // @include media-query($large) {
  //   width: 75%;
  //   margin: 0 auto;
  // }

  li {
    padding: 1rem;
    width: 100%;

    @include media-query($medium) {
      width: 50%
    }

    @include media-query($large) {
      width: 33%
    }
  }

  a {
    background: darken(white, 2%);
    border: 1px solid #cec1c6;
    display: flex;
    min-height: 100px;
    transition: all .15s ease-out;
    box-shadow: 0 0 0 0 rgba($black, 0), 0 0 0 0 rgba($black, 0);

    &:hover, &:focus {
      text-decoration: none;
      @include shadow-hover;
    }
  }

  img {
    @include shadow-hover;
    position: relative;
    max-height: 100px;
    left: -8px;
    top: -8px;
  }

  h4 {
    padding: 1rem;
  }
}


.news--heading__title {
  @include heading__primary;
}

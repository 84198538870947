.header {
  background: $alpha-primary-color;
  @include gradient($alpha-primary-color, $alpha-header-gradient);
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  position: relative;
  width: 100%;
  z-index: 20;

  .logo-block {
    width: 50px;
    display: flex;
    align-items: center;
    margin-right: 1rem;

    @include media-query($medium) {
      width: 92px;
    }

    a {
      display: block;

      &:focus {
        outline: 2px solid $focus;
      }
    }
  }
}

.header--container {
  @include row;
  display: flex;

  @include media-query($medium) {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 1rem;
  }
}

.navigation-block {
  display: none;

  @include media-query($large) {
    display: block;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: -1rem;
    display: flex;
    align-items: center;
  }

  nav {
    display: flex;
    flex-direction: column;
  }

  a {
    color: black;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    @include media-query($medium) {
      padding: 0 0.75rem;
    }

    &:focus {
      outline: 2px solid $focus;
    }
  }
}

.navigation--label {
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0 1rem;
  height: 1.2rem;

  @include media-query($medium) {
    padding: 0 0.75rem;
  }
}

.navigation--links {
  display: flex;
  @include media-query($medium) {
    justify-content: space-evenly;
  }
}


@media only screen
and (device-width : 375px)
and (device-height : 812px)
and (-webkit-device-pixel-ratio : 3) {
  .navigation-name {
    padding-bottom: 20px;
  }
}

.navigation-name {
  opacity: 0.6;

  .active & {
    opacity: 1;
  }
}

.icon--navigation {
  display: flex;
  width: 30px;
  height: 32px;
  opacity: 0.6;

  @include media-query($medium) {
    display: none;
  }

  .active & {
    opacity: 1;
  }
}

.appointment--header {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #0d3187;
  background-position: bottom;
  background-size: cover;

  .blur {
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
    z-index: 1;
  }

  img {
    z-index: 2;
  }
}

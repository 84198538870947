.modal {
  & > *:focus {
    outline: none;
  }
}

.modal--content {
  width: 100%;
  height: 100%;
  max-width: initial;
  max-height: initial;
  margin: 0;
  top: 0;
  transform: none;

  & > .modal--carousel__wrapper {
    border-radius: 0
  }

  @include media-query($large) {
    width: 60%;
    max-width: 700px;
    height: calc(100% - 96px);
    margin: -16px auto 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 1rem;

  }
}

.modal--carousel__wrapper {
  overflow: hidden;
  border-radius: 14;
  transform: scale(1.0);
  background: transparent;
  height: 100%;
}

.modal--footer {
  margin-top: -92px;
  width: 100%;
  text-align: center;
  position: relative;

  @include media-query($large) {
    margin-top: -72px;
  }
}

.modal--dots {
  padding-top: 0;
  margin: 0 auto;

  @include media-query($large) {
    padding-top: 36;
  }
}

.modal--arrow__left {
  width: 48px;
  height: 48px;
  position: absolute;
  top: calc((100% - 96px) / 2 + 24px);
  left: 16px;
  @include media-query($large) {
    left: -96px;
  }
}


.modal--arrow__right {
  width: 48px;
  height: 48px;
  position: absolute;
  top: calc((100% - 96px) / 2 + 24px);
  right: 16px;
  @include media-query($large) {
    right: -96px;
  }
}

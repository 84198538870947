.loves--banner {
  @extend .banner;
  position: relative;
  height: 160px;
  color: white;
  overflow: hidden;

  @include media-query($medium) {
    height: 200px;
    padding-top: 40px;
  }

  @include media-query($large) {
    height: 300px;
    padding-top: 60px;
  }

  h1 {
    border-top: 0;
    font-size: 2rem;
    color: white;

    @include media-query($medium) {
      font-size: 2.5rem;
    }

    @include media-query($large) {
      font-size: 5rem;
    }
  }

  .background-gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%,rgba(0, 0, 0, 0.25) 100%);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@include loves('batman');
@include loves('justice-league');
@include loves('star-wars');
@include loves('wonder-woman');
@include loves('spider-man');
@include loves('the-walking-dead');
@include loves('x-men');

.loves--list {
  @include five-across;

  .loves--item {
    @include five-across-item;
  }
}

.loves--description {
  @include row;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  @include media-query($medium) {
    flex-direction: row;
  }
}

.loves--avatar {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 1rem auto 0;
  z-index: 20;
  border-radius: 50%;
  border: 4px solid white;

  @include media-query($medium) {
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-right: 1rem;
    border: 8px solid white;
  }
}

.banner--container {
  &.has-avatar {
    @include media-query($medium) {
      position: initial;
    }
  }

  .has-avatar {
    @include media-query($medium) {
      margin-left: 216px;
      bottom: 0.5rem;
      position: absolute;
    }

    h1 {
      line-height: 1;
      margin-bottom: 0;
    }
  }
}

.loves--container {
  @include row;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.product--banner {
  @extend .banner;
  height: 160px;
  color: white;
  overflow: hidden;
  position: relative;
  z-index: 10;

  @include media-query($medium) {
    height: 200px;
  }

  @include media-query($large) {
    height: 300px;
  }

  .background-gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%,rgba(0, 0, 0, 0.25) 25%);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  .banner--container {
    padding-top: 0;
  }
}

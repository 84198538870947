.cart--toolbar {
  display: flex;
  justify-content: space-between;
}

.cart-row {
  border-bottom: 1px solid #bfbfc2;
  display:  flex !important;
  justify-content: space-between;
  margin: 0 0 1em 0;

  @include media-query($medium) {
    border-bottom: none;
    display: block !important;
  }

  picture,
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

}
.cart-row__image {
  width: 32%;
  padding-right: 1rem;

  @include media-query($medium) {
    padding-right: 0;
    margin: 1rem auto auto;
    width: 75%;
  }
}

.cart-row__details {
  width: 100%;

  @include media-query($medium) {
    margin: 1rem auto auto;
    width: 75%;
  }
}

.cart-row__name {

}

.cart-row__price-info {
  .product-qty {
    display: inline-block;
  }
  .product-price {
    display: inline-block;
  }
}

.cart-row__qty {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.cart-row__qty-label {
  font-size: 75%;
}


.mylar {
  display: flex;
  margin-top: 1rem;
  text-align: left;

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: inline-block;
    font-size: 0.75rem;
    margin-left: 0;
    margin-right: 0;
  }

  input {
    flex-shrink: 0;
    margin-bottom: 2rem;
    margin-right: 0;
    margin-top: 3px;
    width: 24px;
  }

  @include media-query($medium) {
    margin-top: 1rem;
    text-align: left;

    input {
      margin-bottom: 0;
    }
  }
}

.cart--list {
  @include five-across__cart;

  .cart--item {
    @include five-across-item__cart;
    display: flex;
    flex-direction: row;

    @include media-query($medium) {
      flex-direction: column;
    }
  }
}

.cart--list__tiny {
  @include two-across__cart;

  .cart--item {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;

    @include media-query($medium) {
      width: 50%;
      flex-direction: column;
      padding: 0 1rem 1rem;
    }
  }
}

.cart--summary {
  padding: 0 1rem;
}

$x-large: 1600px;


.cw-button {
  @include shadow-normal;
  background: $blue;
  border-bottom: none;
  border-radius: 2px;
  border: 0;
  color: $white;
  display: inline-block;
  font-family: $font-family;
  font-size: 11px;
  height: 36px;
  line-height: 36px;
  margin: 0;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 1;
  transition: none;
  flex-shrink: 0;

  @include media-query($medium) {
    font-size: 1rem;
    padding: 0 2rem;
    height: 36px;
    line-height: 36px;
    will-change: opacity, transform;
    transition: all .15s ease-out;
    letter-spacing: .5px;
  }

  &:hover {
    @include shadow-normal;

    @include media-query($medium) {
      @include shadow-hover;
    }

    background-color: lighten(#1F52B3, 10%);
    border-bottom: none;
    text-decoration: none;
  }

  &:active,
  &:focus {
    @include shadow-normal;

    @include media-query($medium) {
      @include shadow-focus;
    }

    background-color: lighten(#2763B3, 10%);
    border-bottom: none;
    text-decoration: none;
  }

  &.small {
    padding: 0 1rem;
  }

  &.clear {
    background: transparent;
    box-shadow: none;
    color: $button-blue;
    padding-left: 0;
    padding-right: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
      outline: 0;
    }
  }

  &.wide {
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  &.success {
    background-color: #43AC6A;
    &:hover {
      background-color: darken(#43AC6A, 10%);
    }
    &:focus {
      background-color: darken(#43AC6A, 10%);
    }
  }
  &.white {
    background: white;
    box-shadow: 0 3px 0 darken(white, 10%);
  }

  &.flat {
    background: none;
    box-shadow: none;
    color: $blue;

    &:hover {
      color: lighten($blue, 5%);
    }
  }

  &.link {
    padding-left: 0;
    background: none;
    color: #a3a7ad;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: $blue;
    }

    .selected & {
      color: white;

      &:active,
      &:focus {
        background: 2px solid white;
      }
    }
  }

  &.cw-button--outline {
    background: transparent;
    border: 2px solid $blue;
    color: $blue;
    font-weight: bold;

    &:hover,
    &:active {
      background: rgba($blue, .2);
    }

    &:focus {
      background: $blue;
      color: white;
    }

    &.active {
      background: white;
      pointer-events: none;

      &:focus {
        color: $blue;
        box-shadow: none;
      }
    }
  }

  &.cw-button--outline__white {
    background: transparent;
    border: 2px solid $white;
    color: white;
    font-weight: bold;
    height: 2rem;
    line-height: 2rem;

    &:hover,
    &:active {
      background: rgba($blue, .2);
    }

    &:focus {
      background: $blue;
      color: $white;
    }
  }

  &.cw-button-white {
    border: 2px solid white;
  }

  &.float-icon {
    @include shadow-hover;
    padding: 0;
    width: 36px;
    margin-bottom: 0;
    border-radius: 50%;
    display: inline-block;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      @include shadow-hover;

      @include media-query($medium) {
        @include shadow-focus;
      }
    }

    .icon-plus,
    .icon-minus {
      position: absolute;
      top: 0;
      left: 0;
      transition: none;

      @include media-query($medium) {
        transition: all .15s ease-out;
      }
    }

    .icon-minus {
      opacity: 0;
    }

    .icon-plus {
      opacity: 1;
    }

    .selected & {
      background: #5c6675;
      box-shadow: none;

      &:active,
      &:focus {
        background: #2b3038;
      }


      .icon-minus {
        opacity: 1;
      }

      .icon-plus {
        opacity: 0;
      }

    }
  }

  &.float-icon__qty {
    padding: 0;
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    background: #ffffff;
    border: 1px solid #cccccc;

    &:hover,
    &:focus,
    &:active {
      @include shadow-hover;

      @include media-query($medium) {
        border: 1px solid $blue;
      }
    }

    .icon-plus,
    .icon-minus {
      width: 24px;
      height: 24px;
      margin: 2px;
      position: absolute;
      top: 0;
      left: 0;
      transition: none;

      @include media-query($medium) {
        transition: all .15s ease-out;
      }
    }

    .selected & {
      &:active,
      &:focus {
      }
    }
  }

  &.cw-button-delete {
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: $background;
    color: $background;

    img {
      width: 1rem;
      height: 1rem;
      fill: #988d91;
    }

    &:hover {
      color: #988d91;
    }
  }

  &.tag {
    background: $white;
    border-radius: 3px 0 0 3px;
    box-shadow: none;
    color: $blue;
    display: inline-block;
    font-size: 0.75rem;
    height: 26px;
    line-height: 26px;
    margin: 0 10px 10px 0;
    padding: 0 20px 0 10px;
    position: relative;
    text-decoration: none;
    text-transform: none;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      background: $background;
      border-bottom: 13px solid transparent;
      border-left: 10px solid $white;
      border-top: 13px solid transparent;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
    }

    &.active {
      background: $blue;
      color: $white;

      &:after {
        border-left: 10px solid $blue;
      }
    }
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.26);
    background: rgba(0, 0, 0, 0.12);

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;

      @include media-query($medium) {
        box-shadow: none;
      }

      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button-group {
  .cw-button {
    margin-left: 2px;
  }

  > li:first-child {
    button,
    .button {
      border-left: 2px solid $blue;
    }
  }
}

.icon-plus {
  margin: 2px;
  height: 32px;
  width: 32px;
}

.button-group__spaced {
  @include ul-reset;
  margin: 1rem 0;
  padding: 0 1rem;

  > li {
    margin-right: 1rem;
    display: inline-block;
  }

  > li.list-heading {
    display: block;
    margin-left: 0.5rem;
  }

  a:focus {
    outline: none;
  }
}

.button-group__tags {
  @include ul-reset;
  margin: 1rem 0;
  padding: 0 1rem;

  > li {
    display: inline-block;
  }

  .cw-button {
    display: block;
  }
}


.button-group__breadcrumbs {
  @include ul-reset;
  margin: 1rem 0;

  li {
    position: relative;
    margin-right: 0.5rem;
    display: inline-flex;

    &:after {
      content: '/';
      color: darken($lightgrey, 10%);
      padding-left: 0.5rem;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }

  a {
    color: white;
    text-decoration: underline;
  }
}

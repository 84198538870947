
.flickity-header {
  height: 200px;
  position: relative;
  overflow: hidden;

  @include media-query($medium) {
    height: 300px;
  }

  @include media-query($large) {
    height: 400px;
  }
}

.flickity-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
}

.header-div {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading--subscribe {
  color: $white;

  @include media-query($large) {
    font-size: 3rem;
  }

  span {
    display: block;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    padding: 0.5rem;
    border-radius: 0.5rem;

    @include media-query($medium) {
      display: inline;
    }
  }
}

.sub-slides {
  width: 100%;
  display: block;
  // height: percentage(9/16);
  // height: auto;

  picture {
    display: block;
  }
}

.flickity-viewport {
  height: 100%;
}

.carousel {
  height: 100%;
}

.carousel-cell {
  width: 100%;
  height: 100%;
}

.carousel-cell img {
  display: block;
  width: 100%;
}

.big-list {
  @include ul-reset;
  font-size: 1.33rem;
  width: 100%;
  margin: 2rem 0;

  li {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    fill: $blue;
  }
}

$base-size: 16;
$font-family: 'proxima-nova', 'SF Pro Display', 'San Francisco', -apple-system, 'Helvetica Neue', Roboto, Arial, Helvetica, sans-serif;

//320 by default
html,
body {
  color: $text-color;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.011em;
}

// Header Styles
h1,
h2,
h3 {
  color: $dark-blue;
  font-weight: 400;
  margin: 0;
}

h4,
h5,
h6 {
  color: $dark-blue;
  font-weight: 500;
  margin: 0;
}

a {
  color: $blue;
  outline: 0;
  text-decoration: none;
  transition: color .2s ease-out, border-bottom-color .2s ease-out;

  &:hover {
    text-decoration: underline;
  }
}

p {
  line-height: 1.5em;
}

strong,
b {
  font-weight: 500;
}

.text--instructions {
  padding: 0 1rem;
}

.text-right {
  text-align: right;
}

.small {
  font-size: 0.75em;
}

.large-text {
  font-size: 1.66rem;
  font-weight: 300;

  &:first-child {
    margin-top: 0;
  }
}

@mixin heading__full {
  padding: 0 1rem;
}

@mixin heading__primary {
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1.2;

  @include media-query($medium) {
    font-size: 3.125rem;
  }
}

@mixin heading__secondary {
  font-size: 2rem;
  margin-bottom: 2rem;

  @include media-query($medium) {
    font-size: 3.125rem;
  }
}

@mixin heading__tertiary {
  font-size: 1.5rem;
}

.heading--full {
  @include heading__full;
}

.heading--secondary {
  @include heading__full;
  @include heading__secondary
}

.heading--tertiary {
  @include heading__full;
  @include heading__tertiary
}

.category--list {
  @include five-across__cart;

  .category--item {
    @include five-across-item__cart;
    display: flex;
    flex-direction: row;

    @include media-query($medium) {
      flex-direction: column;
    }
  }
}

.category--buttons {
  @include ul-reset;
  @include cleafix;
  margin-top: 2rem;

  & > li {
    display: inline-block;
    padding: 0 1rem;
  }
}

.checkout--buttons {
  @include clearfix;
  padding: 0 1rem;
}

.checkout--block {
  @include media-query($medium) {
    display: flex;
  }
}

.checkout--billing,
.checkout--shipping,
.checkout--payment {
  padding: 0 1rem;
  margin: 4rem auto 0;

  @include media-query($medium) {
    width: 50%;
  }
}

.subscribe--form {
  margin: 0 auto;

  @include media-query($medium) {
    width: 50%;
  }
}

.checkout--form__group {
  display: flex;

  .checkout--form__field {
    width: 100%;
    padding: 0 1rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0
    }
  }
}

.checkout--comment {
  padding: 0 1rem;
}

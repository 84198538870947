.banner {
  position: relative;
  color: $black;
  height: 200px;

  @include media-query($medium) {
    height: 160px;
  }

  h1 {
    border-top: 1px solid $black;
    color: $black;
    display: inline-block;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0px;
    padding-top: 5px;

    @include media-query($medium) {
      border-top: 2px solid $black;
      font-size: 36px;
    }
  }

  p {
    margin: 0;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 120%;
    background-image: url('/images/subscriptions/banner_s.jpg');
    overflow: hidden;
    background-color: #84403f;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 0;

    @include media-query($medium) {
      background-image: url('/images/subscriptions/banner_l.jpg');
      background-position: 50% 50%;
      padding-top: 275px;
    }
  }

  .background--gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
  }
}

.banner-map-link {
  color: $black;
  position: relative;
  display: flex;
  align-items: center;

  img {
    margin-left: 10px;
    width: 1rem;
    height: 1rem;
  }
}

.banner--container {
  @include row;
  position: relative;
  padding: 2rem 1rem 0;
  z-index: 10;
}

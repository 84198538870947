.confirm--container {
  @include row;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.confirm--heading {
  @include heading__full;
  @include heading__secondary;
}

.confirm--instructions {
  padding: 0 1rem;
}

.confirm--buttons {
  @include clearfix;
}

.confirm--summary {
  padding: 0 1rem;

  @include media-query($large) {
    max-width: percentage(2/3);
    margin-left: percentage(1/6);
  }
}

.confirm--address {
  @include media-query($medium) {
    display: flex;
  }
}

.confirm--address__billing,
.confirm--address__shipping {
  width: 100%
}

.confirm--items {
  @include ul-reset;
}

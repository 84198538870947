.foc-form-container {
  max-width: 40.5rem;
  margin: 0 auto;
}

.foc--cart {
  @include row;
  display: flex;
  justify-content: space-between;
  padding: 10px 1rem;

  @include media-query($medium) {
    padding: 20px;
  }
}

.foc--cart__total {
  small {
    display: block;

    @include media-query($medium) {
      display: inline;
    }
  }
}

.foc--cart__top-button {
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: 0.5rem;

    @include media-query($medium) {
      margin-left: 1rem;
    }
  }
}

// some overrides
[class*="block-grid-"] > li {

  @include media-query($medium) {
    padding: 0 .625rem 1.25rem;
  }
}

.foc--list {
  @include five-across__cart;
  @include clearfix;

  .foc--item {
    @include five-across-item__cart;
    height: auto;

    @include media-query($medium) {
      padding: 0 .625rem 1.25rem;
    }
  }
}

.foc--group {
  margin-top: 4rem;
}

.foc--category {
  @include heading__secondary;
  padding: 0 1rem;
}

.foc--figure {
  border-width: 4px;
  display: flex;
  text-align: left;
  min-height: 1px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;

  &.selected {
    @include shadow-focus;
    margin: -4px;
    border-style: solid;
    border-width: 4px;
    border-color: $blue;
  }

  @include media-query($medium) {
    display: block;
    background: white;
    transition: box-shadow .15s ease-out;
    border-bottom: none;
    margin: 0;
    text-align: center;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba($black, .16), 0 2px 10px 0 rgba($black, .12);
    margin-bottom: 10px;
  }

  .cover {
    width: 150px;
    flex-basis: 150px;
    flex-shrink: 0;

    @include media-query($medium) {
      width: 100%;
      padding: 0px;
      float: none;
      display: block;
      position: relative;
      z-index: 1;
    }
  }

  .info {
    display: block;
    flex-grow: 1;
    font-size: 1rem;
    line-height: 1.2em;
    padding: 10px 0 0 10px;
    text-align: left;
    overflow: hidden;

    @include media-query($medium) {
      width: 100%;
      float: none;
      padding: 10px 16px;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .price {
      @include media-query($medium) {
        display: block;
        margin-top: 0.5em;
        text-align: right;
      }
    }
  }

  .foc-item-actions {
    width: 120px;
    flex-basis: 48px;
    flex-shrink: 0;
    align-items: flex-start;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 10px;

    @include media-query($medium) {
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      width: 100%;
    }

    .float-icon {
      @include media-query($medium) {
      }
    }

    .link {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 0;
    }
  }

  label {
    @include media-query($medium) {
      color: #e2e2e2;
    }
  }
}

.foc--item {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;

  &.selected {
    @include shadow-focus;
    margin: -4px;
    border-style: solid;
    border-width: 4px;
    border-color: $blue;
  }
}

.MuiCardContent-root {
  padding: 1rem;
}

.MuiTypography-caption {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.MuiTypography-root {
  margin: 0;
}

.card--content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.price-tag {
  line-height: 1.2em;
  display: flex;
  flex-direction: column;

  .carousel & {
    display: block;
    text-align: center;
  }

  @include media-query($medium) {
    display: block;
    text-align: center;
    padding: 6px 6px 3px 6px;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    top: 0;
    z-index: 5;
  }

  .discount {
    display: flex;
    flex-direction: column;
    font-size: 12px;

    .carousel & {
      display: block;
      text-align: center;
      padding-left: 0rem;
    }

    @include media-query($medium) {
      padding-left: 0rem;
      display: block;
      color: yellow;
    }

    div {
      padding-right: 0.5rem;

      .carousel & {
        right: 0rem;
      }

      @include media-query($medium) {
        padding-right: 0rem;
      }
    }
  }
}

.foc--item-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.foc--button {
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  overflow: visible;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 3px;
  font-size: 1.125rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.foc--actions {
  justify-content: space-between;
  display: flex;
  padding: 8px;
  align-items: center;
}

.foc--button__info {
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  padding: 6px 8px;
  color: #3f51b5;

  &:hover {
    background-color: rgba(63, 81, 181, 0.08);
  }
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}


$x-large: 1440px;

.show-for-mobile {
  &.sticky__product {
    @include shadow-hover;
    background: $background;
    color: $black;
  }
}

.mobile-product-header {
  display: flex;
  padding: 12px;

  h1 {
    font-size: 18px;
    margin-bottom: 4px;
    margin-right: 12px;
    margin-top: 0;
  }

  p {
    font-size: 12px;
    margin-bottom: 12px;
    margin-top: 0;
  }

  form {
    margin-bottom: 0;
  }
}

.mobile-product-title {
  width: 100%;
}

.hashtag {
  dt {
  }

  dd {
    display: inline;
    margin-left: 0;
    margin-right: 10px;

    &:before {
      color: darken($lightgrey, 10%);
      content: '#';
    }
  }
}

.product--container {
  @include row;
  margin-bottom: 4rem;
  position: relative;
  z-index: 20;
}

.product--data {
  margin-top: -4rem;

  @include media-query($medium) {
    display: flex;
    margin-top: -3rem;
  }
}

.product--image {
  width: 60%;
  margin: 0 20% 2rem;

  &.loading {
    overflow: hidden;
    height: 392px;

    @include media-query($medium) {
      height: 389px;
    }

    @include media-query($large) {
      height: 507px;
    }
  }

  @include media-query($medium) {
    width: 100%;
    margin: 0 1rem;
    flex: 0 0 33%;
  }

  img {
    width: 100%;
    display: block;
  }
}

.product--publisher {
  &.loading {
    overflow: hidden;
    height: 1rem;
    width: 4rem;
  }
}

.product--content {
  margin: 0 1rem;
  flex-grow: 1;
}

.product--heading__container {
  @include media-query($xlarge) {
    display: flex;
  }
}

.product--heading {
  margin-bottom: 2rem;

  @include media-query($medium) {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  @include media-query($xlarge) {
    width: 66%;
  }

  .product--heading__title {
    @include heading__primary;
    margin-bottom: 0;

    &.loading {
      overflow: hidden;
      height: 46px;
      top: 8px;
    }
  }
}

.product--cta {
  padding: 0 1rem;
  margin-bottom: 2rem;
  background: #fff;
  border: 1px solid #C5CAE9;

  @include media-query($large) {
    margin-bottom: 1rem;
  }

  @include media-query($xlarge) {
    margin-top: 4rem;
    width: 33%;
  }

}

.product-description__copy {
  &.loading {
    overflow: hidden;
    height: 300px;
  }
}

.product-related {
  margin-top: 4rem;
}

.related-products--list {
  @include ul-reset;
  display: flex;
  flex-wrap: wrap;

  @include media-query($medium) {
    flex-wrap: nowrap;
  }

  li {
    padding: 0 1rem 1rem;
    width: 50%;

    @include media-query($medium) {
      width: 25%;
    }
  }
}

.product--outofstock {
  margin: 1rem 0;
}


.product--gallery {
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.6);
  padding: 0 1rem 1rem;
  background: #fff;
  border: 1px solid #C5CAE9;
  border-top: 1px solid rgba(0,0,0,0.6);
}

.product--modal {
  .gallery-cell {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel .flickity-page-dots {
    bottom: 1rem;
  }
}

.search-form {
  position: relative;
}

.search-results {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  top: 45px;
  padding: 0 1rem;
  max-width: 332px;

  @include media-query(768px) {
    top: 30px;
  }

  a {
    color: $text-color;
    display: block;
    padding: 4px 8px;
    transition: none;

    &.active {
      background: linear-gradient(#37a3e9, #1285d5);
      box-shadow: 0 1px 0 #69bdf3 inset;
      color: $white;
    }

  }

  ul {
    @include shadow-hover;
    @include ul-reset;
    background: $off-white;
    margin: 0 1rem;
  }

  li {
    border-bottom: 1px solid darken($white, 20%);
  }

  p {
    font-size: .8em;
    margin: 0;

    &.title {
      // font-weight: bold;
    }

    &.sections {

    }
  }
}

.bucket--container {
  @include row;

  @include media-query($medium) {
    display: flex;
  }
}

.bucket--item {
  padding: 0 1rem;
  margin-bottom: 1rem;

  @include media-query($medium) {
    width: percentage(1/3);
  }
}

.bucket {
  background: #591f1f;
  color: white;
  display: block;
  height: 100px;
  overflow: hidden;
  transform: translateZ(0);
  width: 100%;

  @include media-query($medium) {
    height: 155px;
  }

  &:hover {
    text-decoration: none;
  }

  p {
    align-items: baseline;
    background: rgba(black, 0.7);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    margin: 0;
    min-width: 75%;
    padding: 0.5rem 1rem;
    transform: scale3d(.8, .8, 1);
    transition: transform .5s ease-in-out;
    will-change: transform;
  }

  strong {
    font-size: 32px;
    line-height: 1em;
    padding-bottom: 0.4rem;
  }

  small {
    font-size: 1rem;
  }
}

.bucket-content {
  background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/subscriptions/backissues_s.jpg');
  background-position: center center;
  background-size: cover;
  height: 100px;
  transition: transform .5s ease-in-out;
  width: 100%;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale3d(1.1, 1.1, 1);

  @include media-query($medium) {
    height: 155px;
  }

  &:hover {
    transform: scale3d(1, 1, 1);

    p {
      transform: scale3d(1, 1, 1);
    }
  }
}

.bucket-1 {}

.bucket-2 {
  background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/subscriptions/comics_s.jpg');
}

.bucket-3 {
  background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/subscriptions/statues_s.jpg');
}

.home--container {
  @include row;
}

.home--heading {
  @include heading__full;
  @include heading__secondary;
  margin-top: 4rem;
}

.pick-list--list {
  @include five-across;

  .pick-list--item {
    @include five-across-item;
  }
}

.home-site-map {
  @include row;
  margin-top: 4rem;
}

.zeus-comics-presents {
  @include row;
  margin-top: 4rem;
}

.zp--heading {
  @include heading__tertiary;
}

.block--break {
  margin-bottom: 2rem;

  @include media-query($medium) {
    display: flex;
  }
}

.block--break__switch {

  @include media-query($medium) {
    flex-direction: row-reverse;
  }
}

.block--copy {
  margin: 0 1rem;

  @include media-query($medium) {
    width: 60%;
  }
}

.block--image {
  margin: 0 1rem;

  @include media-query($medium) {
    width: 40%;
  }
}

.pick {
  position: absolute;
  width: 13rem;
  min-height: 400px;
  padding: 0 1rem;

  @include media-query($medium) {
    min-height: 400px;
    width: 14.5rem;
  }
}


.feature--container {
  color: white;
}

.feature--item {
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding: 0;
  height: 100%;
  padding: 0;
  position: relative;
  z-index: 2;

  @include media-query($medium) {
    padding: 1rem 2rem;
    flex-direction: row;
  }

  @include media-query($large) {
    padding: 2rem 3rem;
  }
}

.feature--item__container {
  position: absolute;
  width: 280px;
  height: 600px;
  margin: 1rem 1rem 3rem;

  @include media-query($medium) {
    width: 520px;
    height: 270px;
    margin: 1rem 1rem 4rem;
  }

  @include media-query($large) {
    width: 768px;
    height: 420px;
    margin: 1rem 2rem 4rem;
  }

  @include media-query($xlarge) {
    width: 1024px;
    height: 550px;
    margin: 1rem 2rem 4rem;
  }

  background-image: linear-gradient(135deg, #009688 0%, #FFEB3B 100%);
  border-radius: 1rem;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30),
  0 15px 12px rgba(0, 0, 0, 0.22);

  overflow: hidden;
}

.feature__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.feature--image {
  flex-grow: 1;
  flex-shrink: 0;

  @include media-query($medium) {
    max-width: 33%;
  }

  img {
    height: 100%;
    width: 100%;
  }
}


.feature--content {
  padding: 1rem;
  color: white;

  @include media-query($medium) {
    padding: 2rem 1rem;
  }
}

.feature--title {
  @include media-query($medium) {
    font-size: 1.5rem;
  }

  @include media-query($large) {
    font-size: 2.5rem;
  }

  display: block;
  color: white;
}

.feature__marvel {
  // .feature--item {
  //   background-image: url('/images/home/marvel-bg.jpg');
  // }

  .feature--item__container {
    background-image: linear-gradient(135deg, #b60031 0%, #91028f 100%);
  }
}

.feature__dccomics {
  // .feature--item {
  //   background-image: url('/images/home/dccomics-bg.jpg');
  // }

  .feature--item__container {
    background-image: linear-gradient(135deg, #344eab 0%, #00c6b5 100%);
  }
}


.tags {
  margin-bottom: 2rem;
}

.tag-seletor {
  display: flex;
  padding: 0 1rem;
}

.tag-label {
  color: $darkgrey;
  padding-right: 1rem;
  height: 2rem;
  line-height: 2rem;
}

.tag__selected {
  background: $blue;
  border-radius: 3px 0 0 3px;
  box-shadow: none;
  color: white;
  display: inline-block;
  font-size: 0.75rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0 10px 10px 0;
  padding: 0 20px 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: none;

  &:after {
    background: $background;
    border-bottom: 1rem solid transparent;
    border-left: 10px solid $blue;
    border-top: 1rem solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }
}

.tag-selection {
  display: none;

  &.active {
    display: block;
  }
}

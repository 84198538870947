$medium: 768px;
$large: 1024px;
$xlarge: 1500px;

*, ::before, ::after {
  box-sizing: border-box;
}

@import
'utilities/normalize',
'utilities/variables',
'utilities/mixins',
'utilities/typography',
'modules/button',
'utilities/form',
'modules/header',
'modules/search',
'modules/footer',
'modules/modal',
'modules/news',
'modules/banner',
'modules/product-card',
'modules/tags',
'modules/flickity',
'modules/square',
'modules/loader',
'modules/comment',
'pages/checklist',
'pages/cart',
'pages/checkout',
'pages/confirm',
'pages/category',
'pages/home',
'pages/loves',
'pages/products',
'pages/subscribe',
'pages/foc';

.main {
  margin: 0;
  position: relative;
  z-index: 1;
}

.notice--shipping {
  padding: 0 1rem;
  margin: 1rem 0;
}

.page--break__primary,
.page--break__secondary {
  padding-top: 4rem;
  padding-bottom: 2rem;
  h2.home--heading {
    color: white;
    margin-top: 0;
  }
}

.page--break__primary {
  background: #5573b9;
  background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/spider-man-large.gif');
  background-repeat: no-repeat;
  background-size: cover;
}

.page--break__secondary {
  background: #f26522;
  background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/superman-large.gif');
  background-repeat: no-repeat;
  background-size: cover;
}

.hide {
  display: none;
}

.page--container {
  @include row;
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.page--content__group {
  @include media-query($medium) {
    display: flex;
    justify-content: center;
  }
}

.page--content__full {
  width: 100%;
  padding: 0 1rem;
}

.page--content__half {
  padding: 0 1rem;

  @include media-query($medium) {
    width: 50%;
  }

  .page--content__half {
    padding: 0;
  }
}

.page--content__one-third {
  padding: 0 1rem;

  @include media-query($medium) {
    width: 33%;
  }
}

.page--content__two-third {
  padding: 0 1rem;

  @include media-query($medium) {
    width: 67%;
  }
}

.page--content__narrow {
  padding: 0 1rem;

  @include media-query($medium) {
    width: 75%;
  }
}

.show-for-mobile {
  @include media-query($medium) {
    display: none;
  }
}

html,
body {
  background: $background;
}

a {
  &:focus {
    outline: 2px solid $blue;
  }
}

.social {
  margin: 0.5rem 0;
}

.notification {
  position: relative;
  width: 100%;
  z-index: 100;
  align-items: center;
  display: flex;
  min-height: 1rem;
  padding: 1rem;
  background: rgba(190, 211, 241, 0.9);
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

  .notification--content {
    width: 100%;
    display: flex;
    justify-content: center;

    span {
      padding-left: 0.5rem;
    }
  }

  a {
    color: #0752b5;
  }

  img {
    height: 1.25rem;
    margin-right: 3px;
    width: 1.25rem;
  }

  .cw-button.clear {
    position: relative;
    z-index: 100;
    color: black;
    font-size: 1.5rem;
  }
}

.evergreen--notification {
  @include shadow-hover;
  margin: 1rem;
  padding: 1rem;
  background: rgba($darkgrey, 0.8);
  border-radius: 4px;
  color: white;

  a {
    color: white;
    background: black;
    border-radius: 4px;
    padding: 2px 6px;
  }
}

.form-container {
  margin-bottom: 2rem;
}


.affiliate--bullet {
  width: 20px;
}

.invisible {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px 1px 1px 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

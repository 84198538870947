html.modal-active,
html.modal-active body {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}

figure {
  margin: 0;
}

.tether {
  background: #b4c7e7;
  border-bottom: 1px solid #a1b4d2;
}

.sticky {
  z-index: 1100;
}

.sticky-container {
  height: 56px;

  @include media-query($medium) {
    height: 76px;
  }
}

.checklist--image {
  flex-shrink: 0;
  width: 150px;

  @include media-query($medium) {
    width: 100%;
  }
}

.checklist--cart {
  @include row;
  display: flex;
  justify-content: space-between;
  padding: 10px 1rem;

  @include media-query($medium) {
    padding: 20px;
  }
}

.checklist--cart__total {
  small {
    display: block;

    @include media-query($medium) {
      display: inline;
    }
  }
}

.checklist--cart__top-button {
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: 0.5rem;

    @include media-query($medium) {
      margin-left: 1rem;
    }
  }
}

// some overrides
[class*="block-grid-"] > li {

  @include media-query($medium) {
    padding: 0 .625rem 1.25rem;
  }
}

.checklist--list {
  @include five-across__cart;
  @include clearfix;

  .checklist--item {
    @include five-across-item__cart;
    height: auto;

    @include media-query($medium) {
      padding: 0 .625rem 1.25rem;
    }
  }
}

.checklist--group {
  margin-top: 4rem;
}

.checklist--category {
  @include heading__secondary;
  padding: 0 1rem;
}

.checklist--form {
  margin: 4rem auto;

  @include media-query($medium) {
    @include clearfix;
    max-width: 40.5rem;
  }
}

.checklist--fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.checklist--figure {
  @include clearfix;
  border-width: 4px;
  display: flex;
  text-align: left;
  min-height: 1px;
  position: relative;

  &.selected {
    margin: -4px;
    border-style: solid;
    border-width: 4px;
    border-color: $blue;
  }

  @include media-query($medium) {
    display: block;
    border-bottom: none;
    margin: 0;
    position: relative;
    margin-bottom: 10px;
  }

  .cover {
    width: 150px;
    flex-basis: 150px;
    flex-shrink: 0;

    @include media-query($medium) {
      width: 100%;
      padding: 0px;
      float: none;
      display: block;
      position: relative;
      z-index: 1;
    }
  }

  label {
    @include media-query($medium) {
      color: #e2e2e2;
    }
  }
}

.checklist-form-total__inline {
  padding-right: 1rem;
}

.checklist-item-actions {
  width: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0;

  @include media-query($medium) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
  }

  .float-icon {
    @include media-query($medium) {
    }
  }

  .link {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
}

$x-large: 1600px;
.products-list {
  li {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1.25rem;

    @include media-query($medium) {
      border-bottom: 0;
    }
  }
}

.product-card {
  display: flex;
  text-align: left;
  min-height: 1px;
  position: relative;
  width: 100%;

  @include media-query($medium) {
    display: block;
    background: white;
    transition: box-shadow .15s ease-out;
    border-bottom: none;
    margin: 0;
    text-align: center;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba($black, .16), 0 2px 10px 0 rgba($black, .12);
    margin-bottom: 10px;
  }

  .cover {
    width: 72px;
    flex-basis: 72px;
    flex-shrink: 0;

    @include media-query($medium) {
      border-radius: 2px 2px 0 0;
      width: 100%;
      min-height: 150%;
      padding: 0px;
      float: none;
      display: block;
      position: relative;
      z-index: 1;

      img {
        max-width: none;
        width: 100%;
      }
    }

    &.loading {
      overflow: hidden;
      height: 300px;
    }
  }

  .info {
    display: block;
    flex-grow: 1;
    font-size: 12px;
    line-height: 1.2em;
    padding: 10px 0 0 10px;
    text-align: left;
    overflow: hidden;

    @include media-query($medium) {
      width: 100%;
      float: none;
      padding: 10px 16px;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .price {
      @include media-query($medium) {
        display: block;
        margin-top: 0.5em;
        text-align: right;
      }
    }

    .loading {
      overflow: hidden;
      height: 48px;
    }
  }

  label {
    @include media-query($medium) {
      color: #e2e2e2;
    }
  }
}

.product-card__home {
  display: block;
  background: white;
  transition: box-shadow .15s ease-out;
  box-shadow: 0 2px 5px 0 rgba($black, .16), 0 2px 10px 0 rgba($black, .12);

  .info {
    padding: 10px;
  }
}

.product--card__new {
  display: flex;
  margin-bottom: 2rem;

  .product--image {
    width: 50%;
    margin: 0;
    flex-shrink: 0;
  }
}

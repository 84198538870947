.footer--email {
  padding-top: 2em;
  padding-bottom: 2em;
  @include gradient(#ffffff, #e2e2e2);
}

.email-form-container {
  @include row;

  @include media-query($medium) {
    display: flex;
  }
}

.email-form-form {
  padding: 0 1rem;

  @include media-query($medium) {
    width: percentage(1/3);
  }
}

.email-form-content {
  padding: 0 1rem;

  @include media-query($medium) {
    width: percentage(2/3);
  }
}

.email--heading {
  @include heading__tertiary;
}

footer {
  @include gradient(#444444, #222222);
  padding: 1rem 0 5rem 0;
  color: #e2e2e2;

  // Header Styles
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  a {
    text-decoration: none;
    color: $yellow;

    &:hover {
      color: lighten($yellow, 10%);
    }
  }

  .social {
    display: flex;

    div {
      width: 32px;
    }
  }
}

.footer--container {
  @include row;
  @include media-query($medium) {
    display: flex;
  }
}

.footer--bucket {
  padding: 0 1rem;

  @include media-query($medium) {
    width: percentage(1/3);
  }
}

.pullbox--badge {
  display: flex;
  padding: 1rem 0;

  span {
    width: 50%;
  }

  svg {
    width: 72px;
  }
}

textarea {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #FAFAFA;
  border-radius: 0;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+');
  background-position: 100% center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  color: rgba(0, 0, 0, 0.75);
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  padding: 0.5rem;
  border-radius: 0;
  height: 2.3125rem;
  width: 100%;

  // Hide the dropdown arrow shown in newer IE versions
  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: 2px solid $blue;
  }
}
.form-container {
  margin-bottom: 2rem;
}

.form-group {
  flex-grow: 1;

  .error {
    display: none;
  }

  &.has-error {
    input[type='text'],
    input[type='email'],
    select,
    textarea {
      border: 2px red solid;
    }

    label {
      color: red;
    }

    .error {
      display: none;
    }
  }
}

.form-group__radio {
  display: flex;
}

.form-radio-input {
  display: flex;
  padding-right: 1em;

  label {
    line-height: 2rem;
  }

  input[type="radio"] {
    margin: 0.5rem;
  }

}

.fields-tab__container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  max-width: 40.5rem;
  margin: 0 1rem 4rem;
  background-color: $lightgrey;
  border-radius: 100%;

  @include media-query($medium) {
    margin: 0 auto 1rem;
    border-radius: 0;
  }
}

.fields-tab {
  z-index: 1;
  background: $lightgrey;
  height: auto;
  outline: 0;
  color: $text-color;
  position: relative;
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  width: 100%;
  border: 0;
  padding: 1rem;
  border-radius: 2px;

  @include media-query($medium) {
    padding: 1rem 1.5rem;
    border-radius: 2px;
    height: auto;
  }
}

.fields-tab__active {
  z-index: 12;
  background: $blue;
  border: 4px solid $blue;
  margin: -4px;
  color: $white;
  @include shadow-hover;
}

.fields-tab__label {
  text-transform: uppercase;
  letter-spacing: .5px;
}

.fields-tab__description {
  text-transform: none;
  font-size: 0.75rem;
}

.form-container {
  max-width: 40.5rem;
  margin: 0 auto;
}

.form {
  margin: 4rem auto;

  @include media-query($medium) {
    @include clearfix;
    max-width: 40.5rem;
  }
}

.form-block {
  margin-bottom: 1rem;

  @include media-query($medium) {
    display: flex;
  }
}

.form-field {
  width: 100%;
  padding: 0 1rem;

  iframe {
    display: block;
  }
}

.form-field__avatar {
  display: flex;

  .form-avatar {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }
}

.form-fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.form-total__inline {
  padding-right: 1rem;
}

.form-button {
  width: 100%;
  padding: 0 1rem;
  margin-top: 2rem;
  text-align: right;
}

.form-buttons {
  text-align: center;

  @include media-query($medium) {
    text-align: right;
  }

  span {
    display: block;

    @include media-query($medium) {
      display: inline;
    }
  }
}

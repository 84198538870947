@mixin loves($name) {
  .#{$name} {
    .background {
      background-position: top center;
      background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/loves/#{$name}-small.jpg');

      @include mq-2x() {
        background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/loves/#{$name}-small-2x.jpg');
      }

      @include media-query($medium) {
        background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/loves/#{$name}-large.jpg');

        @include mq-2x() {
          background-image: url('https://s3-us-west-2.amazonaws.com/zeuscomicsassets/images/loves/#{$name}-large-2x.jpg');
        }
      }
    }
  }
}

@mixin shadow-normal {
  box-shadow: 0 5px 11px 0 rgba($black, 0), 0 4px 15px 0 rgba($black, 0);
}

@mixin shadow-hover {
  box-shadow: 0 5px 11px 0 rgba($black, .18), 0 4px 15px 0 rgba($black, .15);
}

@mixin shadow-focus {
  box-shadow: 0 10px 11px 0 rgba($black, .3), 0 9px 15px 0 rgba($black, .3);
}

//
// gradients
//
@mixin gradient($from, $to) {
  /* fallback/image non-cover color */
  // background-color: $from;

  // for autoprefixer
  background-image: linear-gradient($from, $to);
}

@mixin animation {
  animation-delay: 0.54s;
  animation: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  transition: all 0.15s ease-in-out;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin ul-reset {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    list-style-image: none;
  }
}

.ul-reset {
  @include ul-reset;
}


@mixin media_query($min,$max: 0) {
  @if ($max == 0) {
    @media screen and (min-width: $min){
      & {
        @content;
      }
    }
  } @else {
    @media screen and (min-width: $min) and (max-width: $max) {
      & {
        @content;
      }
    }
  }
}


// Just because I prefer hyhens
@mixin media-query($min, $max: 0) {
  @if ($max == 0) {
    @media screen and (min-width: $min) {
      & {
        @content;
      }
    }
  } @else {
    @media screen and (min-width: $min) and (max-width: $max) {
      & {
        @content;
      }
    }
  }
}

// We’ll call our mixin ‘mq-2x’ for the sake of brevity.
@mixin mq-2x {
  // This is the very long but bulletproof media query for high resolution displays.
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    // Then we’ll pass our 2x specific rules into our mixin with @content
    @content;
  }
}

@mixin cleafix {
  zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin row {
  margin: 0 auto;
  max-width: 62.5rem;
  width: 100%;

  @include media-query(90.063rem) {
    max-width: 82rem;
  }
}

@mixin row-light {
  margin: 0 auto;
  max-width: 62.5rem;
  width: 100%;
}

@mixin five-across {
  @include row;
  @include clearfix;
  @include ul-reset;

  & > li:nth-of-type(1n) {
    clear: none;
  }

  & > li:nth-of-type(2n + 1) {
    clear: both;
  }

  @include media-query($medium) {
    & > li:nth-of-type(1n) {
      clear: none;
    }

    & > li:nth-of-type(4n+1) {
      clear: both;
    }
  }

  @include media-query($large) {
    & > li:nth-of-type(1n) {
      clear: none;
    }

    & > li:nth-of-type(5n+1) {
      clear: both;
    }
  }
}

@mixin five-across-item {
  list-style: none;
  float: left;
  width: 50%;
  padding: 0 1rem 1.5rem;

  @include media-query($medium) {
    width: 25%;
  }

  @include media-query($large) {
    width: 20%;
  }
}

@mixin five-across__cart {
  @include row;
  @include clearfix;
  @include ul-reset;

  & > li {
    width: 100%;
  }

  & > li:nth-of-type(1n) {
    clear: none;
  }

  > li:nth-of-type(1n+1) {
    clear: both;
  }

  @include media-query($medium) {
    & > li:nth-of-type(1n) {
      clear: none;
    }

    & > li:nth-of-type(4n+1) {
      clear: both;
    }
  }

  @include media-query($large) {
    & > li:nth-of-type(1n) {
      clear: none;
    }

    & > li:nth-of-type(4n+1) {
      clear: none;
    }

    & > li:nth-of-type(5n+1) {
      clear: both;
    }
  }
}

@mixin five-across-item__cart {
  list-style: none;
  float: left;
  width: 100%;
  padding: 0 1rem 1.5rem;

  @include media-query($medium) {
    width: 25%;
  }

  @include media-query($large) {
    width: 20%;
  }
}


@mixin two-across__cart {
  @include row;
  @include clearfix;
  @include ul-reset;

  & > li {
    width: 100%;
  }

  & > li:nth-of-type(1n) {
    clear: none;
  }

  > li:nth-of-type(2n+1) {
    clear: both;
  }
}
